<template>
  <FormulateForm ref="formulateInvoiceData">
    <!-- store row -->
    <b-row>
      <b-col :md="invoice.id ? '7' : '12'">
        <e-store-combo
          id="invoice-store"
          v-model="invoice.storeId"
          name="storeId"
          required
          :clearable="false"
          :disabled="isPurchaseDevolution || isAdjustStock"
          :is-read-only="isReadOnly || isAdjustStock || isInvoiceReturnFromCouponCategory || invoice.invoiceNumber"
          @input-object="onSelectStore"
        />
      </b-col>

      <b-col
        v-if="invoice.id"
        md="5"
      >
        <FormulateInput
          id="invoice-createdBy"
          v-model="invoice.createdBy"
          name="createdBy"
          type="label"
          :label="$t('Criado por')"
        />
      </b-col>

      <b-col md="6">
        <FormulateInput
          id="invoice-store_tradingName"
          v-model="store.companyName"
          name="companyName"
          type="label"
          :label="$t('Razão social')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-store_cnpj"
          v-model="store.cnpj"
          name="cnpj"
          type="label"
          filter="cpfCnpj"
          :mask="['##.###.###/####-##']"
          disabled
          :label="$t('CNPJ')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-store_state_registration"
          v-model="store.stateRegistration"
          name="stateRegistration"
          type="label"
          :label="$t('Inscrição Estadual')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2">
        <FormulateInput
          id="invoice-invoice-number"
          :value="invoice.invoiceNumber"
          name="invoiceNumber"
          type="label"
          :label="$t('Número')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-invoice-series"
          :value="invoice.serialNumber"
          name="serialNumber"
          type="label"
          filter="padLeft"
          :filter-params="[3, '0']"
          :label="$t('Série')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-invoice_issue_date"
          v-model="invoice.issueDate"
          name="issueDate"
          type="label"
          filter="date"
          :placeholder="$t('Selecione')"
          :label="$t('Data Emissão')"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-invoice_movement_date"
          v-model="invoice.movementDate"
          name="movementDate"
          :type="isReadOnly ? 'label' : 'datepicker'"
          filter="date"
          :placeholder="$t('Selecione')"
          :label="$t('Data Movimentação')"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-movement_type"
          v-model="invoice.movementType"
          name="movementType"
          :type="
            isReadOnly || isAdjustStock || isInvoiceReturnFromCouponCategory
              ? 'label'
              : 'vue-select'
          "
          :clearable="false"
          :label="$t('Tipo de movimento')"
          :placeholder="$t('Selecione')"
          :options="movementTypeOptions()"
          class="required"
          validation="required"
          @input="onUpdateItemTotals"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-operation_type"
          v-model="invoice.operationType"
          name="operationType"
          :type="isReadOnly || isAdjustStock ? 'label' : 'vue-select'"
          :clearable="false"
          :label="$t('Tipo de Operação')"
          :placeholder="$t('Selecione')"
          :options="operationTypeOptions()"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-purpose_type"
          v-model="invoice.purposeType"
          :type="
            isReadOnly || isAdjustStock || isInvoiceReturnFromCouponCategory
              ? 'label'
              : 'vue-select'
          "
          :clearable="false"
          :label="$t('Objetivo')"
          :placeholder="$t('Selecione')"
          :options="invoicePurposeTypeOptions()"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="invoice-presence_indicator"
          v-model="invoice.presenceIndicator"
          :type="isReadOnly || isAdjustStock ? 'label' : 'vue-select'"
          :clearable="false"
          :label="$t('Indicador de presença')"
          :placeholder="$t('Selecione')"
          :options="presenceIndicatorOptions()"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="invoice-invoice-natureOperation"
          v-model="invoice.natureOperation"
          name="natureOperation"
          :type="isReadOnly || isAdjustStock ? 'label' : 'vue-select'"
          :options="cfopNatureOperationByInvoiceCategoryOptions"
          :label="$t('Natureza da operação')"
          class="required"
          validation="required"
          @input="onSelectNatureOperation"
        />
      </b-col>
      <b-col
        v-if="!isPurchaseDevolution"
        md="2"
      >
        <FormulateInput
          id="invoice-is_final_customer"
          v-model="invoice.isFinalConsumer"
          :type="isReadOnly || isAdjustStock ? 'status-badge' : 'switch'"
          :label="$t('Consumidor Final?')"
          badge-type="yesNo"
        />
      </b-col>
      <b-col
        v-if="false"
        md="2"
      >
        <template v-if="isReadOnly">
          <p class="h6">
            Modo de contingência?
          </p>
          <e-status-badge
            id="invoice-is_issued_contingency"
            :status="!!invoice.issuedContingency"
            type="yesNo"
          />
        </template>
        <FormulateInput
          v-else
          id="invoice-is_issued_contingency"
          v-model="invoice.issuedContingency"
          type="switch"
          :label="$t('Modo de contingência?')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <FormulateInput
          id="invoice-additional_information"
          v-model="invoice.additionalInformation"
          :type="
            (isReadOnly && invoice.status) || isInvoiceReturnFromCouponCategory
              ? 'label'
              : 'textarea'
          "
          :label="$t('Informações adicionais')"
        />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import { invoiceDomains } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCol,
    BRow,
    EStoreCombo,
    EStatusBadge,
  },

  mixins: [invoiceDomains],

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      required: true,
    },
    isPurchaseDevolution: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('app', ['storeOptions']),
    ...mapGetters('pages/invoice/invoice/invoiceMaintain', [
      'isAdjustStock',
      'isInvoiceReturnFromCouponCategory',
    ]),
    ...mapGetters('pages/invoice/taxClassifications', [
      'getComboCfopNatureOperation',
      'getCfopNatureOperation',
    ]),
    store() {
      return this.invoice.store || {}
    },
    storeLabel() {
      const storeLabel = this.storeOptions.find(s => s.value === this.invoice?.storeId)
      return storeLabel.label
    },

    cfopNatureOperationByInvoiceCategoryOptions() {
      if (this.isReadOnly) return this.getComboCfopNatureOperation

      const categorySelected = this.invoice.invoiceCategory
      const natureOptionsFiltered = this.getComboCfopNatureOperation.filter(opt =>
        opt.cfopInvoiceTypes?.some(type => type?.invoiceCategory === categorySelected)
      )

      return natureOptionsFiltered.length > 0
        ? natureOptionsFiltered
        : this.getComboCfopNatureOperation
    },
  },

  async mounted() {
    this.$emit('load-tax-combos', () => {
      if (this.invoice?.natureOperation) {
        this.onSelectNatureOperation(this.invoice?.natureOperation)
      }
    })
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),
    onSelectStore(val) {
      if (!val) {
        this.$emit('clean-store')
        return
      }
      this.$emit('get-store-by-id', val.id)
    },

    onSelectNatureOperation(val) {
      if (!val) {
        this.$emit('clean-nature-operation')
        return
      }
      if (val.includes('-')) {
        const valCode = Number(val.split('-')[0].trim())
        const foundNatureOperation = this.getCfopNatureOperation.find(
          cfopNature => cfopNature.code === valCode
        )
        if (foundNatureOperation) {
          this.$emit('set-nature-operation', foundNatureOperation)
        }
      }
    },

    onUpdateItemTotals() {
      this.$emit('update-invoice-item-totals')
    },

    validate() {
      this.$refs.formulateInvoiceData.showErrors()
      return this.$refs.formulateInvoiceData.hasErrors
    },
  },
}
</script>

<style></style>
