var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _vm.showAddItem
            ? _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        attrs: {
                          text: _vm.$t("Adicionar item"),
                          variant: "primary",
                        },
                        on: { click: _vm.onAddItem },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "FormulateForm",
                    { ref: "formulateInvoiceItems" },
                    [
                      _c("b-table", {
                        ref: "productTable",
                        staticClass: "bordered",
                        staticStyle: { "max-height": "500px !important" },
                        attrs: {
                          "show-empty": "",
                          striped: "",
                          fields: _vm.fields,
                          items: _vm.items,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(erpSku_name)",
                              fn: function (ref) {
                                var item = ref.item
                                var index = ref.index
                                return [
                                  !_vm.localReadOnly &&
                                  (!item.skuId || !item.sku)
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              _c("FormulateInput", {
                                                staticClass: "ml-50",
                                                staticStyle: {
                                                  width: "100%",
                                                  "min-width": "200px",
                                                  display: "inline-block",
                                                },
                                                attrs: {
                                                  id:
                                                    "invoice-items-sku-searched-" +
                                                    index,
                                                  type: "select-searchable",
                                                  placeholder: _vm.$t(
                                                    "Digite para pesquisar"
                                                  ),
                                                  options: _vm.skusOptions,
                                                  autofocus: "",
                                                  instruction: _vm.$t(
                                                    "Digite pelo menos 3 numeros ou letras para iniciar a pesquisa"
                                                  ),
                                                  validation: "required",
                                                },
                                                on: {
                                                  fetchSearch:
                                                    _vm.fetchSearchSku,
                                                  input: function ($event) {
                                                    return _vm.selectSku(
                                                      item,
                                                      item.skuId
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.skuId,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "skuId", $$v)
                                                  },
                                                  expression: "item.skuId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                margin: "0 10px",
                                              },
                                            },
                                            [
                                              _c("e-button", {
                                                staticClass: "p-5",
                                                attrs: {
                                                  icon: "journal-text",
                                                  variant: "primary",
                                                  title: _vm.$t(
                                                    "Dados fiscais do produto"
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onShowItemDataModal(
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("e-product-router-link", {
                                            attrs: {
                                              "sku-id": item.sku.id,
                                              "product-id": item.sku.productId,
                                              "product-name": item.sku.name,
                                              ean: item.sku.ean,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                            {
                              key: "cell(erpSku_ean)",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row.item.sku ? row.item.sku.ean : ""
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(erpSku_unitOfMeasurement)",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row.item.sku
                                          ? row.item.sku.unitOfMeasurement ||
                                              row.item.unit ||
                                              "-"
                                          : row.item.unit || "-"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(cfop)",
                              fn: function (row) {
                                return [
                                  _vm.canEditCfop
                                    ? _c("FormulateInput", {
                                        attrs: {
                                          id: "invoice-items-cfop-" + row.index,
                                          type: "vue-select",
                                          options:
                                            _vm.cfopOptionsByNatureOperation,
                                          title: _vm.$t("Clique para editar"),
                                          validation: "required",
                                          clearable: false,
                                        },
                                        on: { input: _vm.onUpdateItem },
                                        model: {
                                          value: row.item.cfop,
                                          callback: function ($$v) {
                                            _vm.$set(row.item, "cfop", $$v)
                                          },
                                          expression: "row.item.cfop",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(row.item.cfop)),
                                      ]),
                                ]
                              },
                            },
                            _vm.isStockLossesCategory
                              ? {
                                  key: "cell(stockLossesReason)",
                                  fn: function (row) {
                                    return [
                                      !_vm.localReadOnly
                                        ? _c("FormulateInput", {
                                            class: {
                                              required:
                                                _vm.isStockLossesCategory,
                                            },
                                            attrs: {
                                              id:
                                                "invoice-items-loss-reason-" +
                                                row.index,
                                              type: "vue-select",
                                              options:
                                                _vm.invoiceStockLossesReasonOptions(),
                                              title:
                                                _vm.$t("Clique para editar"),
                                              validation:
                                                _vm.isStockLossesCategory
                                                  ? "required"
                                                  : "",
                                              clearable: false,
                                            },
                                            on: { input: _vm.onUpdateItem },
                                            model: {
                                              value: row.item.stockLossesReason,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "stockLossesReason",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "row.item.stockLossesReason",
                                            },
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .invoiceStockLossesReasonLabel[
                                                  row.item.stockLossesReason
                                                ]
                                              )
                                            ),
                                          ]),
                                    ]
                                  },
                                }
                              : null,
                            {
                              key: "cell(erpSku_quantity)",
                              fn: function (row) {
                                return [
                                  _vm.canEditErpSkuQuantity
                                    ? _c("FormulateInput", {
                                        staticClass: "text-right",
                                        attrs: {
                                          id:
                                            "invoice-items-quantity-" +
                                            row.index,
                                          type: "text-number",
                                          precision: 3,
                                          title: _vm.$t("Clique para editar"),
                                          validation: "required|min:1",
                                          "validation-messages": {
                                            required: _vm.$t("Obrigatório"),
                                            min: _vm.$t("Deve ser maior que 1"),
                                          },
                                        },
                                        on: { blur: _vm.onUpdateItem },
                                        model: {
                                          value: row.item.quantity,
                                          callback: function ($$v) {
                                            _vm.$set(row.item, "quantity", $$v)
                                          },
                                          expression: "row.item.quantity",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("number")(
                                              row.item.quantity,
                                              3
                                            )
                                          )
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(erpSku_unitValue)",
                              fn: function (row) {
                                return [
                                  _vm.isXml
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                row.item.totalValue /
                                                  row.item.quantity
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm.canEditUnitPrice
                                          ? _c("FormulateInput", {
                                              staticClass: "text-right",
                                              staticStyle: { width: "120px" },
                                              attrs: {
                                                id:
                                                  "invoice-items-unit_price-" +
                                                  row.index,
                                                type: "text-number",
                                                currency: "R$",
                                                precision: 3,
                                                title:
                                                  _vm.$t("Clique para editar"),
                                                validation: "required|min:0.01",
                                                "validation-messages": {
                                                  required:
                                                    _vm.$t("Obrigatório"),
                                                  min: _vm.$t(
                                                    "Deve ser maior que 0.01"
                                                  ),
                                                },
                                              },
                                              on: { blur: _vm.onUpdateItem },
                                              model: {
                                                value: row.item.unityPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row.item,
                                                    "unityPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.item.unityPrice",
                                              },
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      row.item.unityPrice
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ],
                                ]
                              },
                            },
                            {
                              key: "cell(aditionalInformation)",
                              fn: function (row) {
                                return [
                                  !_vm.localReadOnly &&
                                  !_vm.isInvoiceComplementary
                                    ? _c("FormulateInput", {
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          id:
                                            "invoice-items-additional_information-" +
                                            row.index,
                                          type: "textarea",
                                          title: _vm.$t("Informação adicional"),
                                        },
                                        model: {
                                          value: row.item.aditionalInformation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row.item,
                                              "aditionalInformation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "row.item.aditionalInformation",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row.item.aditionalInformation ||
                                                "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(fiscal)",
                              fn: function (row) {
                                return [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.$t("NCM")) + ":"),
                                  ]),
                                  _vm._v(" " + _vm._s(row.item.ncm)),
                                  _c("br"),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.$t("CEST")) + ":"),
                                  ]),
                                  _vm._v(" " + _vm._s(row.item.cest)),
                                  _c("br"),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.$t("CST ICMS")) + ":"),
                                  ]),
                                  _vm._v(" " + _vm._s(row.item.stIcms)),
                                  _c("br"),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.$t("CST PIS")) + ":"),
                                  ]),
                                  _vm._v(" " + _vm._s(row.item.stPis)),
                                  _c("br"),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.$t("CST COFINS")) + ":"),
                                  ]),
                                  _vm._v(" " + _vm._s(row.item.stCofins)),
                                  _c("br"),
                                  !_vm.isInvoiceComplementary
                                    ? _c(
                                        "e-button",
                                        {
                                          staticClass: "p-0",
                                          staticStyle: { color: "blue" },
                                          attrs: {
                                            variant: "link",
                                            text: _vm.$t(
                                              !row.detailsShowing
                                                ? "Ver mais"
                                                : "Recolher"
                                            ),
                                          },
                                          on: { click: row.toggleDetails },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.detailsShowing
                                                ? "Hide"
                                                : "Show"
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "row-details",
                              fn: function (row) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-left": "80px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-1" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id: "icms-base-" + row.index,
                                                  label: _vm.$t("Base de ICMS"),
                                                  type: "text-number",
                                                  disabled:
                                                    !_vm.isInvoiceComplementary ||
                                                    _vm.localReadOnly,
                                                  precision: 2,
                                                },
                                                on: { blur: _vm.onUpdateItem },
                                                model: {
                                                  value: row.item.icmsBase,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "icmsBase",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.icmsBase",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id:
                                                    "icms-percent-" + row.index,
                                                  label:
                                                    _vm.$t("Alíquota de ICMS"),
                                                  type: "text-percetage",
                                                  currency: "%",
                                                  disabled:
                                                    !_vm.isInvoiceComplementary ||
                                                    _vm.localReadOnly,
                                                  precision: 4,
                                                },
                                                on: { blur: _vm.onUpdateItem },
                                                model: {
                                                  value: row.item.icmsPercent,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "icmsPercent",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.icmsPercent",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id: "icms-value-" + row.index,
                                                  label:
                                                    _vm.$t("Valor de ICMS"),
                                                  type: "text-number",
                                                  disabled:
                                                    !_vm.isInvoiceComplementary ||
                                                    _vm.localReadOnly,
                                                  precision: 2,
                                                },
                                                on: { blur: _vm.onUpdateItem },
                                                model: {
                                                  value: row.item.icmsValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "icmsValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.icmsValue",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id:
                                                    "icms-st-base-" + row.index,
                                                  label:
                                                    _vm.$t("Base de ICMS ST"),
                                                  type: "text-number",
                                                  disabled:
                                                    !_vm.isInvoiceComplementary ||
                                                    _vm.localReadOnly,
                                                  precision: 2,
                                                },
                                                on: { blur: _vm.onUpdateItem },
                                                model: {
                                                  value: row.item.icmsStBase,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "icmsStBase",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.icmsStBase",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id:
                                                    "icms-st-percent-" +
                                                    row.index,
                                                  label: _vm.$t(
                                                    "Alíquota de ICMS ST"
                                                  ),
                                                  type: "text-percetage",
                                                  disabled:
                                                    !_vm.isInvoiceComplementary ||
                                                    _vm.localReadOnly,
                                                  currency: "%",
                                                  precision: 4,
                                                },
                                                on: { blur: _vm.onUpdateItem },
                                                model: {
                                                  value: row.item.icmsStPercent,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "icmsStPercent",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.icmsStPercent",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id:
                                                    "icms-st-iva-" + row.index,
                                                  label: _vm.$t("IVA"),
                                                  type: "text-percetage",
                                                  disabled:
                                                    !_vm.isInvoiceComplementary ||
                                                    _vm.localReadOnly,
                                                  currency: "%",
                                                  precision: 4,
                                                },
                                                on: { blur: _vm.onUpdateItem },
                                                model: {
                                                  value: row.item.iva,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "iva",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.item.iva",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id:
                                                    "icms-st-value-" +
                                                    row.index,
                                                  label:
                                                    _vm.$t("Valor de ICMS ST"),
                                                  type: "text-number",
                                                  disabled:
                                                    !_vm.isInvoiceComplementary ||
                                                    _vm.localReadOnly,
                                                  precision: 2,
                                                },
                                                on: { blur: _vm.onUpdateItem },
                                                model: {
                                                  value: row.item.icmsStValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "icmsStValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.icmsStValue",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(action)",
                              fn: function (row) {
                                return [
                                  _c("e-grid-actions", {
                                    attrs: {
                                      "show-update": false,
                                      "show-delete": _vm.showRemoveItem,
                                    },
                                    on: {
                                      delete: function ($event) {
                                        return _vm.onRemoveItem(row.item)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("invoice-item-data-sidebar", {
        ref: "invoiceItemDataSidebar",
        attrs: { "is-read-only": _vm.localReadOnly },
        on: { "update-item-tax": _vm.onUpdateItemTax },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }