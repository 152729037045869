<template>
  <section>
    <b-row>
      <b-col md="12">
        <FormulateForm ref="formulateInvoiceEvents">
          <b-table
            ref="invoiceEventsTable"
            show-empty
            striped
            class="bordered"
            :fields="fields"
            :items="events"
          >
            <template #cell(action)="row">
              <e-grid-actions
                :show-update="false"
                :show-delete="false"
                :buttons="gridButtons(row)"
                @download-pdf-event="onDownloadPdfEvent(row)"
                @download-xml-event="onDownloadXmlEvent(row)"
              />
            </template>
          </b-table>
        </FormulateForm>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'
import { alerts } from '@/mixins'
import { mapActions } from 'vuex'
import EGridActions from '@/views/components/EGridActions.vue'

export default {
  components: { BTable, BRow, BCol, EGridActions },
  mixins: [alerts],

  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    events() {
      return this.invoice?.invoiceEvents || []
    },
    fields() {
      return [
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Tipo de Evento'),
          key: 'eventType',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$t(value),
        },
        {
          label: this.$t('Protocolo'),
          key: 'eventProtocol',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Sequencial'),
          key: 'sequentialNumber',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Justificativa'),
          key: 'justification',
          class: 'text-center',
        },
        {
          label: this.$t('Data do Evento'),
          key: 'eventDate',
          class: 'text-center',
          formatter: value => this.$options.filters.date(value),
        },
      ].filter(f => !f.hide)
    },
  },

  mounted() {},

  methods: {
    ...mapActions('pages/invoice/invoice/invoiceMaintain', {
      stInvoiceDownload: 'invoiceDownload',
    }),
    async onRemovePayment(payment) {
      if (
        await this.confirm({
          icon: 'question',
          text: this.$t('Tem certeza que deseja remover este pagamento?'),
        })
      ) {
        this.$emit('remove-payment', payment)
      }
    },

    validate() {
      this.$refs.formulateInvoiceEvents.showErrors()
      return this.$refs.formulateInvoiceEvents.hasErrors
    },

    gridButtons(row) {
      let btns = []

      const isCancelInvoice = row.item.eventType === 'CANCEL_INVOICE'

      btns = [
        {
          icon: 'printer-fill',
          variant: 'primary',
          title: this.$t('Download do PDF'),
          event: 'download-pdf-event',
          hide: isCancelInvoice,
        },
        {
          icon: 'download',
          variant: 'primary',
          title: this.$t('Download do Xml'),
          event: 'download-xml-event',
        },
      ].filter(a => !a.hide)

      return btns
    },

    async onDownloadPdfEvent(row) {
      try {
        const confirmed = await this.confirm({
          title: this.$t('Confirma o download do PDF do Evento?'),
        })
        if (!confirmed) return

        this.loading = true
        const path = row.item.danfePathEvent

        const ret = await this.stInvoiceDownload({
          pathUrl: path,
        })

        if (ret.responsePayload && ret.responsePayload !== '') {
          const linkSource = `data:application/pdf;base64,${ret.responsePayload}`
          const downloadLink = document.createElement('a')
          const fileName = `${this.invoice?.nfeDetail?.accessKey}-evento-${row.item.sequentialNumber}.pdf`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao imprimir o Evento!')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
    async onDownloadXmlEvent(row) {
      try {
        const confirmed = await this.confirm({
          title: this.$t('Confirma o download do xml do evento?'),
        })
        if (!confirmed) return

        this.loading = true
        const path = row.item.xmlPathEvent

        const ret = await this.stInvoiceDownload({
          pathUrl: path,
        })

        if (ret.responsePayload && ret.responsePayload !== '') {
          const linkSource = `data:application/pdf;base64,${ret.responsePayload}`
          const downloadLink = document.createElement('a')
          const fileName = `${this.invoice?.nfeDetail?.accessKey}-evento-${row.item.sequentialNumber}.xml`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao realizar o download do XML!')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
