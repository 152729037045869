var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "formulateSupplier", attrs: { name: _vm.htmlNamePrefix } },
    [
      _c(
        "b-row",
        [
          !_vm.isReadOnly
            ? _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _vm.isSupplier
                    ? _c("e-person-search", {
                        ref: "personSearch",
                        attrs: {
                          id: _vm.htmlIdPrefix + "-supplier",
                          name: _vm.htmlNamePrefix + "Supplier",
                          "is-supplier": "",
                          label: _vm.$t("Fornecedor"),
                          required: "",
                          "default-options": _vm.defaultOptions,
                          "hide-id-or-document-list": _vm.listToHide,
                        },
                        on: { input: _vm.onSelectPerson },
                        model: {
                          value: _vm.personObject.supplierId,
                          callback: function ($$v) {
                            _vm.$set(_vm.personObject, "supplierId", $$v)
                          },
                          expression: "personObject.supplierId",
                        },
                      })
                    : _vm._e(),
                  _vm.isCustomer
                    ? _c("e-person-search", {
                        ref: "personCustomerSearch",
                        attrs: {
                          id: _vm.htmlIdPrefix + "-customer",
                          name: _vm.htmlNamePrefix + "Customer",
                          "is-customer": _vm.isCustomer,
                          "with-store": _vm.isCustomerWithStore,
                          label: _vm.$t("Cliente"),
                          required: "",
                          "default-options": _vm.defaultOptions,
                          "hide-id-or-document-list": _vm.listToHide,
                        },
                        on: { input: _vm.onSelectPerson },
                        model: {
                          value: _vm.personObject.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.personObject, "customerId", $$v)
                          },
                          expression: "personObject.customerId",
                        },
                      })
                    : _vm._e(),
                  _vm.isStore
                    ? _c("e-store-combo", {
                        attrs: {
                          id: _vm.htmlIdPrefix + "-store",
                          name: _vm.htmlNamePrefix + "Store",
                          required: "",
                          clearable: false,
                          "is-read-only": _vm.isReadOnly,
                          "show-all-stores": true,
                          "hide-id-or-document-list": _vm.listToHide,
                        },
                        on: { "input-object": _vm.onSelectStore },
                        model: {
                          value: _vm.store,
                          callback: function ($$v) {
                            _vm.store = $$v
                          },
                          expression: "store",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-name",
                  type: "label",
                  label: _vm.$t("Nome"),
                },
                model: {
                  value: _vm.person.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.person, "name", $$v)
                  },
                  expression: "person.name",
                },
              }),
            ],
            1
          ),
          _vm.isSupplier
            ? [
                _c(
                  "b-col",
                  { attrs: { md: "3" } },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        id: "supplier-document",
                        type: "label",
                        filter: "cpfCnpj",
                        mask: ["##.###.###/####-##"],
                        disabled: "",
                        label: _vm.$t("CNPJ"),
                      },
                      model: {
                        value: _vm.person.document,
                        callback: function ($$v) {
                          _vm.$set(_vm.person, "document", $$v)
                        },
                        expression: "person.document",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "3" } },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        id: "supplier-state-registration",
                        type: "label",
                        label: _vm.$t("Inscrição Estadual"),
                      },
                      model: {
                        value: _vm.person.stateRegistration,
                        callback: function ($$v) {
                          _vm.$set(_vm.person, "stateRegistration", $$v)
                        },
                        expression: "person.stateRegistration",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.isCustomer || _vm.isStore
            ? [
                _c(
                  "b-col",
                  { attrs: { md: "3" } },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        id: "supplier-document",
                        type: "label",
                        filter: "cpfCnpj",
                        mask: ["###.###.###-##", "##.###.###/####-##"],
                        disabled: "",
                        label: _vm.$t("CPF/CNPJ"),
                      },
                      model: {
                        value: _vm.person.document,
                        callback: function ($$v) {
                          _vm.$set(_vm.person, "document", $$v)
                        },
                        expression: "person.document",
                      },
                    }),
                  ],
                  1
                ),
                _vm.person.stateRegistration
                  ? _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "supplier-state-registration",
                            type: "label",
                            label: _vm.$t("Inscrição Estadual"),
                          },
                          model: {
                            value: _vm.person.stateRegistration,
                            callback: function ($$v) {
                              _vm.$set(_vm.person, "stateRegistration", $$v)
                            },
                            expression: "person.stateRegistration",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.person.rg
                  ? _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "supplier-rg",
                            type: "label",
                            label: _vm.$t("RG"),
                          },
                          model: {
                            value: _vm.person.rg.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.person.rg, "document", $$v)
                            },
                            expression: "person.rg.document",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-supplier-phone",
                  value: _vm.personPhone,
                  type: "label",
                  filter: "phone",
                  label: _vm.$t("Telefone"),
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm.person.address
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("e-address", {
                    attrs: { value: _vm.person.address, "read-only": true },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }