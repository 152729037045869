var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "FormulateForm",
                { ref: "formulateInvoiceEvents" },
                [
                  _c("b-table", {
                    ref: "invoiceEventsTable",
                    staticClass: "bordered",
                    attrs: {
                      "show-empty": "",
                      striped: "",
                      fields: _vm.fields,
                      items: _vm.events,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(action)",
                        fn: function (row) {
                          return [
                            _c("e-grid-actions", {
                              attrs: {
                                "show-update": false,
                                "show-delete": false,
                                buttons: _vm.gridButtons(row),
                              },
                              on: {
                                "download-pdf-event": function ($event) {
                                  return _vm.onDownloadPdfEvent(row)
                                },
                                "download-xml-event": function ($event) {
                                  return _vm.onDownloadXmlEvent(row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }