<template>
  <FormulateForm
    ref="formulateSupplier"
    :name="htmlNamePrefix"
  >
    <b-row>
      <b-col
        v-if="!isReadOnly"
        md="12"
      >
        <e-person-search
          v-if="isSupplier"
          :id="`${htmlIdPrefix}-supplier`"
          ref="personSearch"
          v-model="personObject.supplierId"
          :name="`${htmlNamePrefix}Supplier`"
          is-supplier
          :label="$t('Fornecedor')"
          required
          :default-options="defaultOptions"
          :hide-id-or-document-list="listToHide"
          @input="onSelectPerson"
        />
        <e-person-search
          v-if="isCustomer"
          :id="`${htmlIdPrefix}-customer`"
          ref="personCustomerSearch"
          v-model="personObject.customerId"
          :name="`${htmlNamePrefix}Customer`"
          :is-customer="isCustomer"
          :with-store="isCustomerWithStore"
          :label="$t('Cliente')"
          required
          :default-options="defaultOptions"
          :hide-id-or-document-list="listToHide"
          @input="onSelectPerson"
        />
        <e-store-combo
          v-if="isStore"
          :id="`${htmlIdPrefix}-store`"
          v-model="store"
          :name="`${htmlNamePrefix}Store`"
          required
          :clearable="false"
          :is-read-only="isReadOnly"
          :show-all-stores="true"
          :hide-id-or-document-list="listToHide"
          @input-object="onSelectStore"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="supplier-name"
          v-model="person.name"
          type="label"
          :label="$t('Nome')"
        />
      </b-col>
      <template v-if="isSupplier">
        <b-col md="3">
          <FormulateInput
            id="supplier-document"
            v-model="person.document"
            type="label"
            filter="cpfCnpj"
            :mask="['##.###.###/####-##']"
            disabled
            :label="$t('CNPJ')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="supplier-state-registration"
            v-model="person.stateRegistration"
            type="label"
            :label="$t('Inscrição Estadual')"
          />
        </b-col>
      </template>
      <template v-if="isCustomer || isStore">
        <b-col md="3">
          <FormulateInput
            id="supplier-document"
            v-model="person.document"
            type="label"
            filter="cpfCnpj"
            :mask="['###.###.###-##', '##.###.###/####-##']"
            disabled
            :label="$t('CPF/CNPJ')"
          />
        </b-col>
        <b-col
          v-if="person.stateRegistration"
          md="3"
        >
          <FormulateInput
            id="supplier-state-registration"
            v-model="person.stateRegistration"
            type="label"
            :label="$t('Inscrição Estadual')"
          />
        </b-col>
        <b-col
          v-if="person.rg"
          md="3"
        >
          <FormulateInput
            id="supplier-rg"
            v-model="person.rg.document"
            type="label"
            :label="$t('RG')"
          />
        </b-col>
      </template>
      <b-col md="5">
        <FormulateInput
          id="supplier-supplier-phone"
          :value="personPhone"
          type="label"
          filter="phone"
          :label="$t('Telefone')"
        />
      </b-col>
    </b-row>

    <b-row v-if="person.address">
      <b-col md="12">
        <e-address
          :value="person.address"
          :read-only="true"
        />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { EPersonSearch, EStoreCombo, EAddress } from '@/views/components'

export default {
  name: 'PersonForm',

  components: {
    BCol,
    BRow,
    EAddress,
    EPersonSearch,
    EStoreCombo,
  },

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    // Deve ter o atributo supplier ou customer no objeto
    personObject: {
      type: Object,
      required: true,
    },
    isSupplier: {
      type: Boolean,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
    isCustomerWithStore: {
      type: Boolean,
      default: false,
    },
    isStore: {
      type: Boolean,
      default: false,
    },

    /**
     * Ocultar opção no combo pelo id ou document
     * ex:
     * [{id: null, document: '2465489754'}]
     * [{id: 5, document: null}]
     */
    listToHide: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      store: null,
    }
  },

  computed: {
    htmlIdPrefix() {
      return `${this.$attrs.id || 'person'}`
    },
    htmlNamePrefix() {
      return `${this.$attrs.name || 'person'}`
    },

    person() {
      if (this.isCustomer || this.isStore) {
        return this.personObject?.customer || {}
      }

      return this.personObject?.supplier || {}
    },
    personPhone() {
      if (this.isCustomer || this.isStore) {
        return this.personObject?.customer?.telephone?.number
      }
      return this.personObject?.supplier?.telephone?.number
    },

    defaultOptions() {
      if (this.isCustomer || this.isStore) {
        const customer = this.personObject?.customer
        if (customer && Object.keys(customer).length > 0) return [customer]
      }

      const supplier = this.personObject?.supplier
      if (supplier && Object.keys(supplier).length > 0) return [supplier]

      return []
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'personObject.customerId': function (val) {
      if (val == null) this.store = null
    },
  },

  methods: {
    async onSelectStore(val) {
      if (!val) {
        this.$emit('clean-person')
        return
      }
      this.$emit('get-person-by-document', val.cnpj)
    },
    async onSelectPerson(val) {
      if (!val) {
        this.$emit('clean-person')
        return
      }
      this.$emit('get-person-by-id', val)
    },

    async validate() {
      this.$refs.formulateSupplier.showErrors()
      return this.$refs.formulateSupplier.hasErrors
    },

    reset() {
      if (this.$refs.personSearch) {
        this.$refs.personSearch.reset()
      }
      if (this.$refs.personCustomerSearch) {
        this.$refs.personCustomerSearch.reset()
      }
    },
  },
}
</script>

<style></style>
