var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "formulateInvoiceData" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: _vm.invoice.id ? "7" : "12" } },
            [
              _c("e-store-combo", {
                attrs: {
                  id: "invoice-store",
                  name: "storeId",
                  required: "",
                  clearable: false,
                  disabled: _vm.isPurchaseDevolution || _vm.isAdjustStock,
                  "is-read-only":
                    _vm.isReadOnly ||
                    _vm.isAdjustStock ||
                    _vm.isInvoiceReturnFromCouponCategory ||
                    _vm.invoice.invoiceNumber,
                },
                on: { "input-object": _vm.onSelectStore },
                model: {
                  value: _vm.invoice.storeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "storeId", $$v)
                  },
                  expression: "invoice.storeId",
                },
              }),
            ],
            1
          ),
          _vm.invoice.id
            ? _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-createdBy",
                      name: "createdBy",
                      type: "label",
                      label: _vm.$t("Criado por"),
                    },
                    model: {
                      value: _vm.invoice.createdBy,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice, "createdBy", $$v)
                      },
                      expression: "invoice.createdBy",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-store_tradingName",
                  name: "companyName",
                  type: "label",
                  label: _vm.$t("Razão social"),
                },
                model: {
                  value: _vm.store.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.store, "companyName", $$v)
                  },
                  expression: "store.companyName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-store_cnpj",
                  name: "cnpj",
                  type: "label",
                  filter: "cpfCnpj",
                  mask: ["##.###.###/####-##"],
                  disabled: "",
                  label: _vm.$t("CNPJ"),
                },
                model: {
                  value: _vm.store.cnpj,
                  callback: function ($$v) {
                    _vm.$set(_vm.store, "cnpj", $$v)
                  },
                  expression: "store.cnpj",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-store_state_registration",
                  name: "stateRegistration",
                  type: "label",
                  label: _vm.$t("Inscrição Estadual"),
                },
                model: {
                  value: _vm.store.stateRegistration,
                  callback: function ($$v) {
                    _vm.$set(_vm.store, "stateRegistration", $$v)
                  },
                  expression: "store.stateRegistration",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-invoice-number",
                  value: _vm.invoice.invoiceNumber,
                  name: "invoiceNumber",
                  type: "label",
                  label: _vm.$t("Número"),
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-invoice-series",
                  value: _vm.invoice.serialNumber,
                  name: "serialNumber",
                  type: "label",
                  filter: "padLeft",
                  "filter-params": [3, "0"],
                  label: _vm.$t("Série"),
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "invoice-invoice_issue_date",
                  name: "issueDate",
                  type: "label",
                  filter: "date",
                  placeholder: _vm.$t("Selecione"),
                  label: _vm.$t("Data Emissão"),
                  validation: "required",
                },
                model: {
                  value: _vm.invoice.issueDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "issueDate", $$v)
                  },
                  expression: "invoice.issueDate",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "invoice-invoice_movement_date",
                  name: "movementDate",
                  type: _vm.isReadOnly ? "label" : "datepicker",
                  filter: "date",
                  placeholder: _vm.$t("Selecione"),
                  label: _vm.$t("Data Movimentação"),
                  validation: "required",
                },
                model: {
                  value: _vm.invoice.movementDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "movementDate", $$v)
                  },
                  expression: "invoice.movementDate",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "invoice-movement_type",
                  name: "movementType",
                  type:
                    _vm.isReadOnly ||
                    _vm.isAdjustStock ||
                    _vm.isInvoiceReturnFromCouponCategory
                      ? "label"
                      : "vue-select",
                  clearable: false,
                  label: _vm.$t("Tipo de movimento"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.movementTypeOptions(),
                  validation: "required",
                },
                on: { input: _vm.onUpdateItemTotals },
                model: {
                  value: _vm.invoice.movementType,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "movementType", $$v)
                  },
                  expression: "invoice.movementType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "invoice-operation_type",
                  name: "operationType",
                  type:
                    _vm.isReadOnly || _vm.isAdjustStock
                      ? "label"
                      : "vue-select",
                  clearable: false,
                  label: _vm.$t("Tipo de Operação"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.operationTypeOptions(),
                  validation: "required",
                },
                model: {
                  value: _vm.invoice.operationType,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "operationType", $$v)
                  },
                  expression: "invoice.operationType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-purpose_type",
                  type:
                    _vm.isReadOnly ||
                    _vm.isAdjustStock ||
                    _vm.isInvoiceReturnFromCouponCategory
                      ? "label"
                      : "vue-select",
                  clearable: false,
                  label: _vm.$t("Objetivo"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.invoicePurposeTypeOptions(),
                },
                model: {
                  value: _vm.invoice.purposeType,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "purposeType", $$v)
                  },
                  expression: "invoice.purposeType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-presence_indicator",
                  type:
                    _vm.isReadOnly || _vm.isAdjustStock
                      ? "label"
                      : "vue-select",
                  clearable: false,
                  label: _vm.$t("Indicador de presença"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.presenceIndicatorOptions(),
                },
                model: {
                  value: _vm.invoice.presenceIndicator,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "presenceIndicator", $$v)
                  },
                  expression: "invoice.presenceIndicator",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "invoice-invoice-natureOperation",
                  name: "natureOperation",
                  type:
                    _vm.isReadOnly || _vm.isAdjustStock
                      ? "label"
                      : "vue-select",
                  options: _vm.cfopNatureOperationByInvoiceCategoryOptions,
                  label: _vm.$t("Natureza da operação"),
                  validation: "required",
                },
                on: { input: _vm.onSelectNatureOperation },
                model: {
                  value: _vm.invoice.natureOperation,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "natureOperation", $$v)
                  },
                  expression: "invoice.natureOperation",
                },
              }),
            ],
            1
          ),
          !_vm.isPurchaseDevolution
            ? _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-is_final_customer",
                      type:
                        _vm.isReadOnly || _vm.isAdjustStock
                          ? "status-badge"
                          : "switch",
                      label: _vm.$t("Consumidor Final?"),
                      "badge-type": "yesNo",
                    },
                    model: {
                      value: _vm.invoice.isFinalConsumer,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice, "isFinalConsumer", $$v)
                      },
                      expression: "invoice.isFinalConsumer",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _vm.isReadOnly
                    ? [
                        _c("p", { staticClass: "h6" }, [
                          _vm._v(" Modo de contingência? "),
                        ]),
                        _c("e-status-badge", {
                          attrs: {
                            id: "invoice-is_issued_contingency",
                            status: !!_vm.invoice.issuedContingency,
                            type: "yesNo",
                          },
                        }),
                      ]
                    : _c("FormulateInput", {
                        attrs: {
                          id: "invoice-is_issued_contingency",
                          type: "switch",
                          label: _vm.$t("Modo de contingência?"),
                        },
                        model: {
                          value: _vm.invoice.issuedContingency,
                          callback: function ($$v) {
                            _vm.$set(_vm.invoice, "issuedContingency", $$v)
                          },
                          expression: "invoice.issuedContingency",
                        },
                      }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-additional_information",
                  type:
                    (_vm.isReadOnly && _vm.invoice.status) ||
                    _vm.isInvoiceReturnFromCouponCategory
                      ? "label"
                      : "textarea",
                  label: _vm.$t("Informações adicionais"),
                },
                model: {
                  value: _vm.invoice.additionalInformation,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "additionalInformation", $$v)
                  },
                  expression: "invoice.additionalInformation",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }