<template>
  <FormulateForm ref="formulateInvoiceValues">
    <b-row>
      <b-col md="2">
        <FormulateInput
          v-if="isReadOnly"
          id="invoice-freight"
          :value="invoice.freightValue"
          type="label"
          filter="currency"
          :label="$t('Valor do Frete')"
        />
        <FormulateInput
          v-else
          id="invoice-freight"
          v-model="invoice.freightValue"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Valor do Frete')"
          @blur="onUpdateItemsTotals"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          v-if="isReadOnly"
          id="invoice-insurance"
          :value="invoice.insuranceValue"
          type="label"
          filter="currency"
          :label="$t('Valor do Seguro')"
        />
        <FormulateInput
          v-else
          id="invoice-insurance"
          v-model="invoice.insuranceValue"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Valor do Seguro')"
          @blur="onUpdateItemsTotals"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          v-if="isReadOnly"
          id="invoice-discount"
          :value="invoice.discountValue"
          type="label"
          filter="currency"
          :label="$t('Descontos')"
        />
        <FormulateInput
          v-else
          id="invoice-discount"
          v-model="invoice.discountValue"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Descontos')"
          @blur="onUpdateItemsTotals"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          v-if="invoice.totalUnityDiscountValue > 0"
          id="invoice-unity-discount"
          :value="invoice.totalUnityDiscountValue"
          type="label"
          filter="currency"
          :label="$t('Descontos Unitários')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          v-if="isReadOnly"
          id="invoice-other-costs"
          :value="invoice.otherCostsValue"
          type="label"
          filter="currency"
          :label="$t('Outros Custos')"
        />
        <FormulateInput
          v-else
          id="invoice-other-costs"
          v-model="invoice.otherCostsValue"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Outros Custos')"
          @blur="onUpdateItemsTotals"
        />
      </b-col>
      <!-- <b-col md="12">
        <barcode
          id="invoice-invoice-key"
          class="text-center"
          style="overflow: hidden"
          :value="invoice.invoiceKey || 123"
          height="50"
        >
          Invoice Key
        </barcode>
        <FormulateInput
          id="invoice-input-invoice-key"
          v-model.trim="invoice.invoiceKey"
          class="mt-1 required"
          :label="$t('Código de Barras da NF')"
          type="text"
          inputmode="numeric"
          validation="required"
          :pattern="/[0-9]*/"
        />
      </b-col> -->
    </b-row>

    <b-row>
      <!-- <b-col md="2">
        <FormulateInput
          id="invoice-taxes"
          v-model="invoice.totalTaxValue"
          type="label"
          filter="currency"
          :label="$t('Impostos')"
        />
      </b-col> -->
      <b-col md="2">
        <FormulateInput
          id="invoice-icms-value"
          v-model="invoice.totalIcms"
          type="label"
          filter="currency"
          :label="$t('Valor de ICMS')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-icms-st-value"
          v-model="invoice.totalIcmsSt"
          type="label"
          filter="currency"
          :label="$t('Valor de ICMS ST')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-ipi-value"
          v-model="invoice.totalIpi"
          type="label"
          filter="currency"
          :label="$t('Valor de IPI')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-products-value"
          v-model="invoice.productsValue"
          type="label"
          filter="currency"
          :label="$t('Valor dos Produtos')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="invoice-total-value"
          v-model="invoice.totalValue"
          type="label"
          filter="currency"
          :label="$t('Valor da Nota')"
        />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import { debounce } from 'lodash'
import { BCol, BRow } from 'bootstrap-vue'
// import VueBarcode from 'vue-barcode'

export default {
  components: {
    BCol,
    BRow,
    // barcode: VueBarcode,
  },

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {
    // usado debounce para evitar N chamadas qnd usuário tecla tab
    // eslint-disable-next-line func-names
    onUpdateItemsTotals: debounce(function () {
      this.$emit('update-invoice-item-totals')
    }, 500),

    validate() {
      this.$refs.formulateInvoiceValues.showErrors()
      return this.$refs.formulateInvoiceValues.hasErrors
    },
  },
}
</script>

<style></style>
