<template>
  <e-sidebar
    id="invoiceItemDataSidebar"
    :show="showSidebar"
    :fetching="loadingModal"
    :title="modalTitle"
    width="500px"
    @hidden="onHideModal"
    @save="onConfirm"
  >
    <template #content>
      <FormulateForm
        ref="invoiceItemSidebarForm"
        name="invoiceItemSidebarForm"
      >
        <b-row>
          <b-col>
            <FormulateInput
              id="invoice_item_sidebar-product_name"
              v-model="invoiceItemInfo.productName"
              type="label"
              :label="$t('Produto')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="invoice_item_sidebar-ncm"
              v-model="invoiceItem.ncm"
              :type="isReadOnly ? 'label' : 'text'"
              class="required"
              validation="required"
              :label="$t('NCM')"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="invoice_item_sidebar-cest"
              v-model="invoiceItem.cest"
              :type="isReadOnly ? 'label' : 'text'"
              class="required"
              validation="required"
              :label="$t('CEST')"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import _ from 'lodash'
import { BRow, BCol } from 'bootstrap-vue'
import { alerts } from '@/mixins'
import { getInitialInvoiceItem } from '@/store/pages/invoice/invoice/invoice-maintain'
import ESidebar from '@/views/components/ESidebar.vue'

const getInitialInvoiceItemInfo = () => ({
  productName: '',
})

export default {
  components: { BRow, BCol, ESidebar },

  mixins: [alerts],

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modalTitle: '',
      showSidebar: false,
      loadingModal: false,
      busy: false,
      invoiceItem: getInitialInvoiceItem(),
      invoiceItemInfo: getInitialInvoiceItemInfo(),
    }
  },

  computed: {},

  methods: {
    async show(itemData) {
      this.showSidebar = true

      try {
        this.loadingModal = true
        if (itemData) {
          this.modalTitle = this.$t('Editar dados fiscais do item')
          const defaultInvoice = getInitialInvoiceItem()
          const defaultInvoiceInfo = getInitialInvoiceItemInfo()
          this.invoiceItem = {
            ...defaultInvoice,
            ...itemData,
          }

          this.invoiceItemInfo = {
            ...defaultInvoiceInfo,
            productName: itemData.sku?.name || '-',
          }
        }

        if (this.isReadOnly) {
          this.modalTitle = this.$t('Visualizar dados fiscais do item')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingModal = false
      }
    },

    onHideModal() {
      this.cleanModalData()
      this.showSidebar = false
    },

    cleanModalData() {
      this.invoiceItemInfo = getInitialInvoiceItemInfo()
      this.invoiceItem = getInitialInvoiceItem()
      this.modalTitle = ''
    },

    onConfirm() {
      this.$refs.invoiceItemSidebarForm.showErrors()
      if (this.hasInvalidInputs()) {
        this.showInvalidDataMessage()
        return
      }

      this.$emit('update-item-tax', this.invoiceItem)
      this.onHideModal()
    },

    hasInvalidInputs() {
      if (_.isEmpty(this.invoiceItem.cest)) return true
      if (_.isEmpty(this.invoiceItem.ncm)) return true

      return false
    },
  },
}
</script>

<style lang="scss" scoped></style>
