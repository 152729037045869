var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      id: "invoiceItemDataSidebar",
      show: _vm.showSidebar,
      fetching: _vm.loadingModal,
      title: _vm.modalTitle,
      width: "500px",
    },
    on: { hidden: _vm.onHideModal, save: _vm.onConfirm },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "invoiceItemSidebarForm",
                attrs: { name: "invoiceItemSidebarForm" },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "invoice_item_sidebar-product_name",
                            type: "label",
                            label: _vm.$t("Produto"),
                          },
                          model: {
                            value: _vm.invoiceItemInfo.productName,
                            callback: function ($$v) {
                              _vm.$set(_vm.invoiceItemInfo, "productName", $$v)
                            },
                            expression: "invoiceItemInfo.productName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "invoice_item_sidebar-ncm",
                            type: _vm.isReadOnly ? "label" : "text",
                            validation: "required",
                            label: _vm.$t("NCM"),
                          },
                          model: {
                            value: _vm.invoiceItem.ncm,
                            callback: function ($$v) {
                              _vm.$set(_vm.invoiceItem, "ncm", $$v)
                            },
                            expression: "invoiceItem.ncm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "invoice_item_sidebar-cest",
                            type: _vm.isReadOnly ? "label" : "text",
                            validation: "required",
                            label: _vm.$t("CEST"),
                          },
                          model: {
                            value: _vm.invoiceItem.cest,
                            callback: function ($$v) {
                              _vm.$set(_vm.invoiceItem, "cest", $$v)
                            },
                            expression: "invoiceItem.cest",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }