<template>
  <section>
    <b-row
      v-if="!isXml && !readOnly"
      class="mb-1"
    >
      <b-col class="d-flex justify-content-end">
        <e-button
          :text="$t('Adicionar pagamento')"
          variant="primary"
          @click="onAddPayment"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <FormulateForm ref="formulatePayments">
          <b-table
            ref="productTable"
            show-empty
            striped
            class="bordered"
            :fields="fields"
            :items="payments"
          >
            <template #cell(paymentMethod)="row">
              <!-- validation-messages é necessário pois o formulate entende label vazia como null -->
              <e-payment-method
                :id="`invoice-payments-payment_type-${row.index}`"
                v-model="row.item.paymentMethodId"
                :is-read-only="readOnly"
                :placeholder="$t('Clique para selecionar')"
                label=""
                required
                :validation-messages="{
                  required: $t('Este campo é obrigatório'),
                }"
                :flag="getPaymentMethodFlag"
              />
            </template>

            <template #cell(paymentValue)="row">
              <template v-if="!readOnly">
                <FormulateInput
                  v-if="!canPaymentValueBeZero(row.item)"
                  :id="`invoice-payments-payment_value-${row.index}`"
                  v-model="row.item.paymentValue"
                  type="text-number"
                  currency="R$"
                  :precision="3"
                  class="text-right"
                  validation="required|min:0.01"
                  :validation-messages="{
                    required: $t('Este campo é obrigatório'),
                    min: $t('Este campo deve ser maior que 0.01'),
                  }"
                />
                <FormulateInput
                  v-else
                  :id="`invoice-payments-payment_value-${row.index}`"
                  v-model="row.item.paymentValue"
                  type="text-number"
                  currency="R$"
                  :precision="3"
                  class="text-right"
                  validation="required"
                  :validation-messages="{ required: $t('Este campo é obrigatório') }"
                />
              </template>
              <span v-else>{{ row.item.paymentValue | currency }}</span>
            </template>

            <template #cell(changeValue)="row">
              <FormulateInput
                v-if="!readOnly"
                :id="`invoice-payments-payment_change_value-${row.index}`"
                v-model="row.item.changeValue"
                type="text-number"
                currency="R$"
                :precision="3"
                class="text-right"
              />
              <span v-else>{{ row.item.changeValue | currency }}</span>
            </template>
            <template #cell(dueDate)="row">
              <FormulateInput
                v-if="!readOnly"
                :id="`invoice-payments-payment_duedate-${row.index}`"
                v-model="row.item.dueDate"
                type="datepicker"
                class="text-right"
              />
              <span v-else>{{ row.item.dueDate | date }}</span>
            </template>

            <template #cell(action)="row">
              <e-grid-actions
                :show-update="false"
                :show-delete="true"
                @delete="onRemovePayment(row.item)"
              />
            </template>
          </b-table>
        </FormulateForm>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'
import { accountTypes, paymentTypes } from '@/mixins'
import EGridActions from '@/views/components/EGridActions.vue'
import EButton from '@/views/components/EButton.vue'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'

export default {
  components: { BTable, BRow, BCol, EGridActions, EButton, EPaymentMethod },
  mixins: [paymentTypes, accountTypes],

  props: {
    isXml: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    getPaymentMethodFlag() {
      if (this.readOnly) return 'all'

      const options = {
        Return: 'invoiceReturn',
        Sale: 'invoiceSale',
        Transference: 'invoiceTransference',
        StockLosses: 'invoiceStockLosses',
        ProductBonus: 'invoiceProductBonus',
        StoreLending: 'invoiceStoreLending',
      }
      return options[this.invoice?.invoiceCategory] || 'all'
    },

    payments() {
      return this.invoice?.payments?.filter(p => !p.isDeleted) || []
    },
    paymentMethod() {
      const [firstPayment] = this.payments
      return firstPayment?.paymentMethod?.name || { name: '-' }
    },

    fields() {
      return [
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.isXml || this.readOnly,
        },
        {
          label: this.$t('Forma de Pagamento'),
          key: 'paymentMethod',
          class: 'text-center',
          thStyle: 'width: 500px;',
        },
        {
          label: this.$t('Valor'),
          key: 'paymentValue',
          tdClass: 'text-right',
          thStyle: 'width: 180px;',
        },
        {
          label: this.$t('Data de Vencimento'),
          key: 'dueDate',
          tdClass: 'text-right',
          thStyle: 'width: 150px;',
          formatter: value => this.$options.filters.date(value),
        },
        {
          label: this.$t('Troco'),
          key: 'changeValue',
          tdClass: 'text-right',
          thStyle: 'width: 180px;',
        },
      ].filter(f => !f.hide)
    },
  },

  mounted() {},

  methods: {
    onAddPayment() {
      this.$emit('add-payment')
    },

    async onRemovePayment(payment) {
      if (
        await this.confirm({
          icon: 'question',
          text: this.$t('Tem certeza que deseja remover este pagamento?'),
        })
      ) {
        this.$emit('remove-payment', payment)
      }
    },

    validate() {
      this.$refs.formulatePayments.showErrors()
      return this.$refs.formulatePayments.hasErrors
    },

    canPaymentValueBeZero(item) {
      const withoutPayment = this.getPaymentMethodByMethod(this.paymentTypeEnum.WITHOUT_PAYMENT)
      return item?.paymentMethodId === String(withoutPayment?.id)
    },
  },
}
</script>

<style scoped></style>
