var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !_vm.isXml && !_vm.readOnly
        ? _c(
            "b-row",
            { staticClass: "mb-1" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-end" },
                [
                  _c("e-button", {
                    attrs: {
                      text: _vm.$t("Adicionar pagamento"),
                      variant: "primary",
                    },
                    on: { click: _vm.onAddPayment },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "FormulateForm",
                { ref: "formulatePayments" },
                [
                  _c("b-table", {
                    ref: "productTable",
                    staticClass: "bordered",
                    attrs: {
                      "show-empty": "",
                      striped: "",
                      fields: _vm.fields,
                      items: _vm.payments,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(paymentMethod)",
                        fn: function (row) {
                          return [
                            _c("e-payment-method", {
                              attrs: {
                                id:
                                  "invoice-payments-payment_type-" + row.index,
                                "is-read-only": _vm.readOnly,
                                placeholder: _vm.$t("Clique para selecionar"),
                                label: "",
                                required: "",
                                "validation-messages": {
                                  required: _vm.$t("Este campo é obrigatório"),
                                },
                                flag: _vm.getPaymentMethodFlag,
                              },
                              model: {
                                value: row.item.paymentMethodId,
                                callback: function ($$v) {
                                  _vm.$set(row.item, "paymentMethodId", $$v)
                                },
                                expression: "row.item.paymentMethodId",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(paymentValue)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly
                              ? [
                                  !_vm.canPaymentValueBeZero(row.item)
                                    ? _c("FormulateInput", {
                                        staticClass: "text-right",
                                        attrs: {
                                          id:
                                            "invoice-payments-payment_value-" +
                                            row.index,
                                          type: "text-number",
                                          currency: "R$",
                                          precision: 3,
                                          validation: "required|min:0.01",
                                          "validation-messages": {
                                            required: _vm.$t(
                                              "Este campo é obrigatório"
                                            ),
                                            min: _vm.$t(
                                              "Este campo deve ser maior que 0.01"
                                            ),
                                          },
                                        },
                                        model: {
                                          value: row.item.paymentValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row.item,
                                              "paymentValue",
                                              $$v
                                            )
                                          },
                                          expression: "row.item.paymentValue",
                                        },
                                      })
                                    : _c("FormulateInput", {
                                        staticClass: "text-right",
                                        attrs: {
                                          id:
                                            "invoice-payments-payment_value-" +
                                            row.index,
                                          type: "text-number",
                                          currency: "R$",
                                          precision: 3,
                                          validation: "required",
                                          "validation-messages": {
                                            required: _vm.$t(
                                              "Este campo é obrigatório"
                                            ),
                                          },
                                        },
                                        model: {
                                          value: row.item.paymentValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row.item,
                                              "paymentValue",
                                              $$v
                                            )
                                          },
                                          expression: "row.item.paymentValue",
                                        },
                                      }),
                                ]
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(row.item.paymentValue)
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(changeValue)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly
                              ? _c("FormulateInput", {
                                  staticClass: "text-right",
                                  attrs: {
                                    id:
                                      "invoice-payments-payment_change_value-" +
                                      row.index,
                                    type: "text-number",
                                    currency: "R$",
                                    precision: 3,
                                  },
                                  model: {
                                    value: row.item.changeValue,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "changeValue", $$v)
                                    },
                                    expression: "row.item.changeValue",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(row.item.changeValue)
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(dueDate)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly
                              ? _c("FormulateInput", {
                                  staticClass: "text-right",
                                  attrs: {
                                    id:
                                      "invoice-payments-payment_duedate-" +
                                      row.index,
                                    type: "datepicker",
                                  },
                                  model: {
                                    value: row.item.dueDate,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "dueDate", $$v)
                                    },
                                    expression: "row.item.dueDate",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("date")(row.item.dueDate))
                                  ),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(action)",
                        fn: function (row) {
                          return [
                            _c("e-grid-actions", {
                              attrs: {
                                "show-update": false,
                                "show-delete": true,
                              },
                              on: {
                                delete: function ($event) {
                                  return _vm.onRemovePayment(row.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }