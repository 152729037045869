var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "formulateInvoiceValues" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.isReadOnly
                ? _c("FormulateInput", {
                    attrs: {
                      id: "invoice-freight",
                      value: _vm.invoice.freightValue,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Valor do Frete"),
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "invoice-freight",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Valor do Frete"),
                    },
                    on: { blur: _vm.onUpdateItemsTotals },
                    model: {
                      value: _vm.invoice.freightValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice, "freightValue", $$v)
                      },
                      expression: "invoice.freightValue",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.isReadOnly
                ? _c("FormulateInput", {
                    attrs: {
                      id: "invoice-insurance",
                      value: _vm.invoice.insuranceValue,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Valor do Seguro"),
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "invoice-insurance",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Valor do Seguro"),
                    },
                    on: { blur: _vm.onUpdateItemsTotals },
                    model: {
                      value: _vm.invoice.insuranceValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice, "insuranceValue", $$v)
                      },
                      expression: "invoice.insuranceValue",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.isReadOnly
                ? _c("FormulateInput", {
                    attrs: {
                      id: "invoice-discount",
                      value: _vm.invoice.discountValue,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Descontos"),
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "invoice-discount",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Descontos"),
                    },
                    on: { blur: _vm.onUpdateItemsTotals },
                    model: {
                      value: _vm.invoice.discountValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice, "discountValue", $$v)
                      },
                      expression: "invoice.discountValue",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.invoice.totalUnityDiscountValue > 0
                ? _c("FormulateInput", {
                    attrs: {
                      id: "invoice-unity-discount",
                      value: _vm.invoice.totalUnityDiscountValue,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Descontos Unitários"),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.isReadOnly
                ? _c("FormulateInput", {
                    attrs: {
                      id: "invoice-other-costs",
                      value: _vm.invoice.otherCostsValue,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Outros Custos"),
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "invoice-other-costs",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Outros Custos"),
                    },
                    on: { blur: _vm.onUpdateItemsTotals },
                    model: {
                      value: _vm.invoice.otherCostsValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice, "otherCostsValue", $$v)
                      },
                      expression: "invoice.otherCostsValue",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-icms-value",
                  type: "label",
                  filter: "currency",
                  label: _vm.$t("Valor de ICMS"),
                },
                model: {
                  value: _vm.invoice.totalIcms,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "totalIcms", $$v)
                  },
                  expression: "invoice.totalIcms",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-icms-st-value",
                  type: "label",
                  filter: "currency",
                  label: _vm.$t("Valor de ICMS ST"),
                },
                model: {
                  value: _vm.invoice.totalIcmsSt,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "totalIcmsSt", $$v)
                  },
                  expression: "invoice.totalIcmsSt",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-ipi-value",
                  type: "label",
                  filter: "currency",
                  label: _vm.$t("Valor de IPI"),
                },
                model: {
                  value: _vm.invoice.totalIpi,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "totalIpi", $$v)
                  },
                  expression: "invoice.totalIpi",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-products-value",
                  type: "label",
                  filter: "currency",
                  label: _vm.$t("Valor dos Produtos"),
                },
                model: {
                  value: _vm.invoice.productsValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "productsValue", $$v)
                  },
                  expression: "invoice.productsValue",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "invoice-total-value",
                  type: "label",
                  filter: "currency",
                  label: _vm.$t("Valor da Nota"),
                },
                model: {
                  value: _vm.invoice.totalValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoice, "totalValue", $$v)
                  },
                  expression: "invoice.totalValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }